import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import Pomodoro from './components/Pomodoro';
import Dashboard from './components/dashboard/Dashboard';
import AIFeedback from './components/AIFeedback';
import Setup from './components/Setup';
import FAQ from './components/FAQ';
import About from './components/About';
import { useTheme } from './ThemeContext';
import Login from './components/users/Login';
import Logout from './components/users/Logout'
import Register from './components/users/Register';
import Todo from './components/todo/Todo';
import logoImage from './logo.png';
import Footer from './components/footer/Footer';
import TermsOfUse from './components/TermsOfUse';
import PrivacyPolicy from './components/PrivacyPolicy';
import { useUser } from './components/users/UserContext';

function App() {
  const { theme } = useTheme();
  // State for work and break durations with default values
  const [workDuration, setWorkDuration] = useState(25 * 60); // 25 minutes in seconds
  const [breakDuration, setBreakDuration] = useState(5 * 60); // 5 minutes in seconds
  const { username } = useUser();

  // Update functions could be passed down to Setup component if needed
  const handleWorkDurationChange = (newDuration) => setWorkDuration(newDuration);
  const handleBreakDurationChange = (newDuration) => setBreakDuration(newDuration);


  return (
    <Router>
      <div className={`App ${theme}`}>
        <nav className="App-nav">
          {/* <ThemeToggle /> */}
          <Link to="/">Home</Link>
          {!username && <Link to="/login">Login</Link>}
          {!username && <Link to="/register">Register</Link>}
          <Link to="/setup">Setup</Link>
          <Link to="/faq">FAQ</Link>
          <Link to="/about">About</Link>
          {username && (
            <>
            <div key={username} className="welcome-message">Signed in : {username}</div>,
            <Link key="logout-link" to="/logout">Logout</Link>
            </>
            )}
        </nav>
        <header className="App-header">
          <Link to="/">
            <img src={logoImage} alt="Logo" style={{ width: '200px', height: 'auto' }} />
          </Link>
          <p>Pomodoro Timer Enhanced by AI recommendations (Beta Version)</p>
        </header>
        <div className="Sub-nav">
          <Link to="/" className="Link">Pomodoro</Link>
          <Link to="/dashboard" className="Link">Dashboard</Link>
          <Link to="/aifeedback" className="Link">AI Feedback</Link>
          <Link to="/todo" className="Link">To do List</Link>
        </div>
        <div className="App-content">
          <Routes>
            <Route path="/" element={<Pomodoro workDuration={workDuration} breakDuration={breakDuration} username={username} />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/todo" element={<Todo username={username} />} />
            <Route path="/aifeedback" element={<AIFeedback />} />
            <Route path="/setup" element={<Setup onWorkChange={handleWorkDurationChange} onBreakChange={handleBreakDurationChange} />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/about" element={<About />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
